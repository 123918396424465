export default {
  "AFG": "Afghanistan",
  "AGO": "Angola",
  "ALB": "Albania",
  "AND": "Andorra",
  "ARE": "United Arab Emirates",
  "ARG": "Argentina",
  "ARM": "Armenia",
  "ATG": "Antigua and Barbuda",
  "AUS": "Australia",
  "AUT": "Austria",
  "AZE": "Azerbaijan",
  "BDI": "Burundi",
  "BEL": "Belgium",
  "BEN": "Benin",
  "BFA": "Burkina Faso",
  "BGD": "Bangladesh",
  "BGR": "Bulgaria",
  "BHR": "Bahrain",
  "BHS": "Bahamas",
  "BIH": "Bosnia and Herzegovina",
  "BLR": "Belarus",
  "BLZ": "Belize",
  "BOL": "Bolivia",
  "BRA": "Brazil",
  "BRB": "Barbados",
  "BRN": "Brunei Darussalam",
  "BTN": "Bhutan",
  "BWA": "Botswana",
  "CAF": "Central African Republic",
  "CAN": "Canada",
  "CHE": "Switzerland",
  "CHL": "Chile",
  "CHN": "China",
  "CIV": "Côte d'Ivoire",
  "CMR": "Cameroon",
  "COD": "Democratic Republic of the Congo",
  "COG": "Congo",
  "COL": "Colombia",
  "CPV": "Cabo Verde",
  "CRI": "Costa Rica",
  "CUB": "Cuba",
  "CYP": "Cyprus",
  "CZE": "Czechia",
  "DEU": "Germany",
  "DJI": "Djibouti",
  "DMA": "Dominica",
  "DNK": "Denmark",
  "DOM": "Dominican Republic",
  "DPS": "DPS",
  "DZA": "Algeria",
  "ECU": "Ecuador",
  "EGY": "Egypt",
  "ERI": "Eritrea",
  "ESH": "Western Sahara",
  "ESP": "Spain",
  "EST": "Estonia",
  "ETH": "Ethiopia",
  "FIN": "Finland",
  "FJI": "Fiji",
  "FRA": "France",
  "GAB": "Gabon",
  "GBR": "United Kingdom",
  "GEO": "Georgia",
  "GHA": "Ghana",
  "GIN": "Guinea",
  "GMB": "Gambia",
  "GNB": "Guinea-Bissau",
  "GNQ": "Equatorial Guinea",
  "GRC": "Greece",
  "GRD": "Grenada",
  "GTM": "Guatemala",
  "GUY": "Guyana",
  "HND": "Honduras",
  "HRV": "Croatia",
  "HTI": "Haiti",
  "HUN": "Hungary",
  "IDN": "Indonesia",
  "IND": "India",
  "IRL": "Ireland",
  "IRN": "Iran",
  "IRQ": "Iraq",
  "ISL": "Iceland",
  "ISR": "Israel",
  "ITA": "Italy",
  "JAM": "Jamaica",
  "JOR": "Jordan",
  "JPN": "Japan",
  "KAZ": "Kazakhstan",
  "KEN": "Kenya",
  "KGZ": "Kyrgyzstan",
  "KHM": "Cambodia",
  "KNA": "Saint Kitts and Nevis",
  "KOR": "South Korea",
  "KWT": "Kuwait",
  "LAO": "Lao People's Democratic Republic",
  "LBN": "Lebanon",
  "LBR": "Liberia",
  "LBY": "Libya",
  "LCA": "Saint Lucia",
  "LIE": "Liechtenstein",
  "LKA": "Sri Lanka",
  "LTU": "Lithuania",
  "LUX": "Luxembourg",
  "LVA": "Latvia",
  "MAR": "Morocco",
  "MCO": "Monaco",
  "MDA": "Moldova",
  "MDG": "Madagascar",
  "MDV": "Maldives",
  "MEX": "Mexico",
  "MKD": "Republic of North Macedonia",
  "MLI": "Mali",
  "MLT": "Malta",
  "MMR": "Myanmar",
  "MNE": "Montenegro",
  "MNG": "Mongolia",
  "MOZ": "Mozambique",
  "MRT": "Mauritania",
  "MSZ": "MSZ",
  "MUS": "Mauritius",
  "MWI": "Malawi",
  "MYS": "Malaysia",
  "NAM": "Namibia",
  "NER": "Niger",
  "NGA": "Nigeria",
  "NIC": "Nicaragua",
  "NLD": "Netherlands",
  "NOR": "Norway",
  "NPL": "Nepal",
  "NZL": "New Zealand",
  "OMN": "Oman",
  "PAK": "Pakistan",
  "PER": "Panama",
  "PHL": "Philippines",
  "PNG": "Papua New Guinea",
  "POL": "Poland",
  "PRT": "Portugal",
  "PRY": "Paraguay",
  "QAT": "Qatar",
  "RKS": "Republic of Kosovo",
  "ROU": "Romania",
  "RUS": "Russia",
  "RWA": "Rwanda",
  "SAU": "Saudi Arabia",
  "SDN": "Sudan",
  "SEN": "Senegal",
  "SGP": "Singapore",
  "SLE": "Sierra Leone",
  "SLV": "El Salvador",
  "SMR": "San Marino",
  "SOM": "Somalia",
  "SRB": "Serbia",
  "SSD": "South Sudan",
  "STP": "Sao Tome and Principe",
  "SUR": "Suriname",
  "SVK": "Slovakia",
  "SVN": "Slovenia",
  "SWE": "Sweden",
  "SWZ": "Eswatini",
  "SYC": "Seychelles",
  "SYR": "Syrian Arab Republic",
  "TCD": "Chad",
  "TGO": "Togo",
  "THA": "Thailand",
  "TLS": "Timor-Leste",
  "TTO": "Trinidad and Tobago",
  "TUN": "Tunisia",
  "TUR": "Turkey",
  "TWN": "Taiwan",
  "TZA": "Tanzania",
  "UGA": "Uganda",
  "UKR": "Ukraine",
  "URY": "Uruguay",
  "USA": "United States of America",
  "UZB": "Uzbekistan",
  "VAT": "Holy See",
  "VCT": "Saint Vincent and the Grenadines",
  "VEN": "Venezuela",
  "VNM": "Vietnam",
  "WBG": "West Bank/Gaza Strip",
  "YEM": "Yemen",
  "ZAF": "South Africa",
  "ZMB": "Zambia",
  "ZWE": "Zimbabwe"
}